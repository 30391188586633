<template>
    <div class="ServerConfiguration">
        <p class="p justify">
            复数智科提供完备的服务器配置和解决方案。我们与戴尔、曙光、浪潮、宝德、超微等品牌建立了良好的合作，并一直努力打造强有力的工作团体，不断完善，不断创新。公司本着想客户之所想、及客户之所及原则，提供全程周到的服务，包括技术咨询、技术培训，售前、售中、售后服务。公司拥有经验丰富的服务团队，为科研用户提供整体化的解决方案。我们坚持品质第一，客户的利益即我们的利益，我们致力于为您提供更好的服务。
        </p>
        <b-row>
            <b-col
                cols="12"
                lg="6"
                align-self="center"
                class="text-center"
            >
                <img
                    class="img-info server-configuration-img1"
                    src="@/assets/images/server-configuration/daopianshi.fw.png"
                >
                <div class="tags">
                    <span>刀片式服务器</span>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="6"
                align-self="center"
                class="text-center"
            >
                <img
                    class="img-info server-configuration-img2"
                    src="@/assets/images/server-configuration/jijiashi.fw.png"
                >
                <div class="tags">
                    <span>机架式服务器</span>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="6"
                align-self="center"
                class="text-center"
            >
                <img
                    class="img-info server-configuration-img3"
                    src="@/assets/images/server-configuration/gongzuozhan.fw.png"
                >
                <div class="tags">
                    <span>工作站</span>
                </div>
            </b-col>
            <b-col
                cols="12"
                lg="6"
                align-self="center"
                class="text-center"
            >
                <img
                    class="img-info server-configuration-img4"
                    src="@/assets/images/server-configuration/gaomidu.fw.png"
                >
                <div
                    class="tags gaomidu"
                >
                    <span>高密度服务器</span>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'ServerConfiguration',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '服务器配置')
      }
}
</script>
<style lang="less" scoped>
.ServerConfiguration{
    margin-bottom:6.25rem;
    .tags{
        background: rgba(0, 0, 0, 0.85);
        width:fit-content;
        color:#fff;
        padding: 0.3125rem 1.875rem;
        border-radius: 9px;
        margin: 1rem auto;

    }
     .server-configuration-img1{
        width: 60%;
     }
     .server-configuration-img2{
        width: 80%;
     }
     .server-configuration-img3{
        width: 55%;
     }
     .server-configuration-img4{
        width: 80%;
     }
}
</style>
